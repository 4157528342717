body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 40px;
  /* min-height: 100%; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.width_500 {
  width: 1000px;
}

html {
  height: 100%;
  overflow: auto;
}
.textAlign-center {
  text-align: center;
}
div .background-color_red {
  background-color: red;
}
.margin_0 {
  margin: 0px;
}

div .textAlign_left {
  text-align: left;
}

ul.breadcrumb li a {
  font-weight: bold;
  color: #0275d8;
  text-decoration: none;
}
ul.breadcrumb li + li:before {
  padding: 8px;
  color: black;
  content: ">";
  font-weight: bold;
}

.App-App-1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.marginY_10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.marginBottom_0 {
  margin-bottom: 0px;
}

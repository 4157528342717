.username-wrapper {
  position: relative;
  cursor: pointer;
}

.username {
  text-transform: capitalize;
  text-align: center;
  font-size: 12px;
  color: #ccc;
}

.user-dropdown-wrapper {
  width: 100%;
  padding-top: 4rem;
  top: 4px;
  right: 0;
  position: absolute;
}

.user-dropdown {
  width: 100%;
  background: white;
  color: #595959;
  border-radius: 4px;
  padding: 8px 20px;
  float: right;
  box-shadow: inset 0px -1px 1px -1px #ccc;
  border: 1px solid #ccc;
  top: -18px;
  right: -24px;
  position: relative;
}

.user-dropdown:hover {
  box-shadow: 0px 2px 20px #dcdcdc inset;
}

.user-dropdown-wrapper:hover .user-dropdown {
  display: block !important;
  position: relative;
  z-index: 9;
}

.avatar {
  margin: 6px auto;
  color: black;
}

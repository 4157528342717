.AppbarNavigation {
  display: flex;
  width: 424px;
  justify-content: space-evenly;
  padding-right: 5%;
  margin-left: auto;
}

.AppbarNavigation_link {
  color: white;
  text-decoration: none;
  position: relative;
}

.AppbarNavigation_link.active {
  pointer-events: all;
}

.AppbarNavigation_link.active::after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background: #2870c1;
  bottom: -26px;
}
.title {
  color: #ccc;
}
.title_text {
  display: flex;
  flex-direction: column;
}
